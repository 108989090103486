//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Null from "@/components/Null";
import { ctrlOrder, ctrlBase } from "@/controller";
import { Button, TabPane, Tabs, Alert } from "element-ui";
import { GoodsItem } from "@/components";
export default {
    components: {
        [Alert.name]: Alert,
        [Button.name]: Button,
        [TabPane.name]: TabPane,
        [Tabs.name]: Tabs,
        GoodsItem,
        Null,
    },
    data() {
        return {
            active: "99", //當前選中tab下標

            typeList: [
                { id: "99", name: "全部" },
                { id: "1", name: "待付款" },
                // { id: "0", name: "待發貨" },
                // { id: "2", name: "待收貨" },
                { id: "9", name: "已完成" },
            ],
            list: [],
            lastPage: 1, //上一次page
            ctrlBase,
            // all: [], //全部
            // pay: [], //待付款
            // shiping: [], //待收貨
            // finish: [], //已完成
        };
    },
    created() {
        this.getSoList();
    },
    methods: {
        //獲取訂單列表
        async getSoList() {
            this.list = await ctrlOrder.getSoList({ stat: this.active, lastPage: this.lastPage });
            // console.log(this.list);
        },
        activeChange() {
            this.getSoList();
        },
        //物流查詢頁面
        openWL(url) {
            window.open(url);
        },
    },
};
